<template>
  <ball-drop :is-started="isStarted" />
</template>

<script>
import BallDrop from "@/components/BallDrop";
import { defineComponent } from "vue";
import { Channels } from "@/api/routes";
import { socketManagerModule } from "@/store/modules/socketManager";
import { BallDropEvents } from "@/api/events/BallDropEvents";

export default defineComponent({
  name: "drop",
  components: { BallDrop },
  data() {
    return {
      isStarted: false
    };
  },
  async mounted() {
    const socket = await socketManagerModule.joinChannel(Channels.BALL_DROP);

    socket
      .listen(BallDropEvents.START, () => {
        this.isStarted = true;
      })
      .listen(BallDropEvents.RESET, () => {
        this.isStarted = false;
      });
  },
  async beforeUnmount() {
    await socketManagerModule.leaveChannel(Channels.BALL_DROP);
  }
});
</script>

<style scoped></style>
