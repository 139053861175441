
import { defineComponent } from "vue";
import { pageManagerModule } from "@/store/modules/pageManager";

export default defineComponent({
  props: {
    isStarted: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    isStarted(v) {
      if (v) {
        setTimeout(() => {
          pageManagerModule.changeEnableFireworks(true);
        }, 10000);
      } else {
        pageManagerModule.changeEnableFireworks(false);
      }
    }
  },
  beforeUnmount() {
    pageManagerModule.changeEnableFireworks(false);
  },
  name: "BallDrop"
});
