<template>
  <div class="h-full">
    <div class="stage w-full">
      <div class="ball-drop">
        <img
          alt="Disco ball for new years drop"
          src="@/assets/disco.svg"
          class="ball"
          :class="{ 'handle-ball': isStarted }"
        />
        <div class="pole bg-gray-50 rounded-t rounded-b"></div>
        <div
          class="platform"
          :class="{
            'handle-platform': isStarted,
            invisible: !isStarted
          }"
        >
          2021
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { pageManagerModule } from "@/store/modules/pageManager";

export default defineComponent({
  props: {
    isStarted: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    isStarted(v) {
      if (v) {
        setTimeout(() => {
          pageManagerModule.changeEnableFireworks(true);
        }, 10000);
      } else {
        pageManagerModule.changeEnableFireworks(false);
      }
    }
  },
  beforeUnmount() {
    pageManagerModule.changeEnableFireworks(false);
  },
  name: "BallDrop"
});
</script>

<style scoped>
/*noinspection CssUnknownTarget*/
@import url("https://fonts.googleapis.com/css?family=Courgette");

.stage {
  width: 100%;
  min-height: 80vh;
  height: 80vh;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.ball-drop {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.ball {
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  position: absolute;
  overflow: hidden;
}

.handle-ball {
  animation: drop 10s linear forwards;
  -webkit-animation: drop 10s linear forwards;
}

.pole {
  background: #000000;
  width: 2rem;
  height: 60vh;
  margin: auto;
}
.platform {
  color: #000000;
  font-size: 7rem;
  margin: 10px auto 0 auto;
  font-family: Courgette, serif;
}

.handle-platform {
  -webkit-animation: show 10s linear;
  animation: show 10s linear;
}

@-webkit-keyframes drop {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(calc(60vh - 7rem));
    transform: translateY(calc(60vh - 7rem));
  }
}

@keyframes drop {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(calc(60vh - 7rem));
    transform: translateY(calc(60vh - 7rem));
  }
}

@-webkit-keyframes show {
  0%,
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes show {
  0%,
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@-webkit-keyframes slide-ball {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(calc(80vh - 8rem));
    transform: translateX(calc(80vh - 8rem));
  }
}

@keyframes slide-ball {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(calc(80vh - 8rem));
    transform: translateX(calc(80vh - 8rem));
  }
}
</style>
